import * as React from "react";
import { useState, useEffect } from 'react';
import { PlasmicHomepage } from "./plasmic/personal_website/PlasmicHomepage";
import { readString } from 'react-papaparse';
import data from "./../work.csv"
import ArticleComponent from "./ArticleComponent";

const Airtable = require('airtable');

function Homepage_(props, ref) {
    const [jsonData, setJsonData] = useState([])
    const [showMoreNum, setShowMoreNum] = useState(4);
    const [visible, setVisible] = useState(false)
    const [firstCheckbox, setFirstCheckbox] = useState(false)
    const [secondCheckbox, setSecondCheckbox] = useState(false)

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [writtenMessage, setWrittenMessage] = useState('')
    const [clicked, setClicked] = useState(false)

    const [ipData, setIpData] = useState('')
    const [sentIpOnLoad, setSentIpOnLoad] = useState(false)

    const getIp = async () => {
        // Connect ipapi.co with fetch()
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        // Set the IP address to the constant `ip`
        setIpData(data)
    }
    // Run `getIP` function above just once when the page is rendered
    useEffect(() => {
        getIp()
    }, [])

    // console.log(ip)
    // console.log(ipData)


    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: "patWSiJ817fjeMrjW.018fdf4614dfb393c9cd50f50f118191c4a44c07ed62a1c5bc2d03356388f9d4"
    });
    var base = Airtable.base('appYPzMCwDBCapMMR');

// send on form submit
    async function sendDataToAirTable() {
        if ((firstCheckbox === false) && (secondCheckbox === true)) {
            await base('ContactForm').create([
                {
                    "fields": {
                        'email': email,
                        'name': name,
                        'message': writtenMessage,
                        'time': new Date(Date.now()).toLocaleString(),
                        'ip': JSON.stringify(ipData)
                    }
                },

            ], function (err, records) {
                if (err) {
                    // console.error(err);
                    return;
                }
                setWrittenMessage('')
                setName('')
                setEmail('')
                setClicked(true)
                records.forEach(function (record) {
                    // console.log(record.getId());
                });
            });
        }
    }

    // send on page visit
    async function sendPageIPToAirtable() {
            await base('AllVisits').create([
                {
                    "fields": {
                        'time': new Date(Date.now()).toLocaleString(),
                        'ip': JSON.stringify(ipData)
                    }
                },

            ], function (err, records) {
                if (err) {
                    // console.error(err);
                    return;
                }
                setSentIpOnLoad(true)
                records.forEach(function (record) {
                    // console.log(record.getId());
                });
            });

    }
    if (sentIpOnLoad === false) {
        // console.log(ipData)
        if (String(ipData).length > 1) {
            setTimeout( function() { sendPageIPToAirtable(); }, 2000);
            setSentIpOnLoad(true)
        }
    }


    useEffect(() => {
        const papaConfig = {
            complete: (results, file) => {
                setJsonData(results['data'])
            },
            download: true,
            header: true,
            error: (error, file) => {
                // console.log('Error while parsing:');
            },
        };
        readString(data, papaConfig);
    }, [])

    // console.log(jsonData)
    // console.log(secondCheckbox)

    return <PlasmicHomepage page={{ ref }} {...props}
        firstBox={{
            onChange: () => { setFirstCheckbox(!firstCheckbox) }
        }}
        secondBox={{
            onChange: () => { setSecondCheckbox(!secondCheckbox) }
        }}

        message={{
            autoFocus: false,
            value: writtenMessage,
            onChange: (e) => {
                setWrittenMessage(e.target.value);
            },
        }}

        email={{
            autoFocus: false,
            value: email,
            onChange: (e) => {
                setEmail(e.target.value);
            },
        }}

        name={{
            autoFocus: false,
            value: name,
            onChange: (e) => {
                setName(e.target.value)
            }
        }}

        submitButton={{
            onClick: () => {
                sendDataToAirTable()
            },
            clicked: clicked
        }}

        articlesList={
            {
                children: jsonData.slice(0, showMoreNum).map((item) => {
                    if (item.image) {
                        return (
                            <ArticleComponent
                                image={item.image}
                                articleTitle={item.title}
                                link={item.link}
                            />
                        )
                    } else {
                        return (
                            <div></div>
                        )
                    }
                })
            }
        }

        showMore={{
            onClick: () => { setShowMoreNum(15); setVisible(true) },
            visibility: visible

        }}

    />;
}

const Homepage = React.forwardRef(Homepage_);

export default Homepage;
