import React from "react";
import Homepage from "./components/Homepage";
import leftPic from "./images/left_image.png"
import rightPic from "./images/right_image.png"
// import {ParallaxProvider, Parallax} from 'react-scroll-parallax';


function Index() {
    //
    // function BackgroundImage(props) {
    //     return (
    //         <>
    //             <img src={props.inputPic}
    //                  style={{width: "100%", height: 700, objectFit: "contain", position: "absolute", top:0, left:0}}/>
    //         </>
    //     )
    // }


    return (
        <Homepage
            // backgroundCircle={
            //     <>
            //         <ParallaxProvider>
            //             <Parallax y={[-50, 50]}>
            //                 <BackgroundImage inputPic={leftPic}/>
            //                 <BackgroundImage inputPic={rightPic}/>
            //             </Parallax>
            //         </ParallaxProvider>
            //     </>
            // }
            // leftCircle={<BackgroundImage inputPic={leftPic} speed={100}/>}
            // rightCircle={<BackgroundImage inputPic={rightPic} speed={500}/> }
        />

    );
}

export default Index;